import React, { useState, useEffect } from "react";
import "./banner.css";
import bannericon1_1 from "../../../Assets/bi111.png";
import bannericon2_1 from "../../../Assets/bi214.png";
import bannericon2_2 from "../../../Assets/bi223.png";
import bannericon2_3 from "../../../Assets/bi232.png";
import bannericon3_1 from "../../../Assets/bi314.png";
import bannericon3_2 from "../../../Assets/bi322.png";
import bannericon3_3 from "../../../Assets/bi333.png";
import bannericonM1_1 from "../../../Assets/bim111.png"
import bannericonM2_1 from "../../../Assets/bim212.png"
import bannericonM2_2 from "../../../Assets/bim221.png"
import bannericonM3_1 from "../../../Assets/bim311.png"
const Banner = () => {
  const texts = [
    {
      text: "The most minimalist of doorbells",
      images: [{ src: bannericon1_1, className: "" }],
    },
    {
      text: "No hardware | No wiring | No batteries⁠",
      images: [
        { src: bannericon2_1, className: "" },
        { src: bannericon2_2, className: "" },
        // { src: bannericon2_3, className: "tilt-image" },
      ],
    },
    {
      text: "FREE basic plan",
      images: [
        { src: bannericon3_1, className: "" },
        // { src: bannericon3_2, className: "" },
        // { src: bannericon3_3, className: "" },
      ],
    },
  ];

  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState("slide-in");


  useEffect(() => {
    const interval = setInterval(() => {
      setAnimationClass("slide-out");
      setTimeout(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setAnimationClass("slide-in");
      }, 1000); // Duration of the slide-out animation
    }, 6000); // Total duration for each slide

    return () => clearInterval(interval);
  }, [texts.length]);

  const currentText = texts[currentTextIndex];

  return (
    <div className="banner-container">
      <div className={`banner-slide ${animationClass}`}>
        <div className="text-container">
          <h1 className="banner-text"><strong>{currentText.text}</strong></h1>
        </div>
        {currentText.images.length > 0 && (
          <div className="images-container">
            {currentText.images.map((image, index) => (
              <img
                key={index}
                src={image.src}
                alt={`icon-${index}`}
                className={`banner-image ${image.className}`}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Banner;
