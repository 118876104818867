import React from "react";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import "./Stick.css";
import facebook from "../../Assets/facebook.png";
import youtube from "../../Assets/youtube.png";
import twitter from "../../Assets/twitter.png";
import instagram from "../../Assets/instagram.png"
const StickySocialIcons = () => {
  return (
    <div className="sticky-social-icons">
      <a
        target="_blank"
        href="https://www.facebook.com/profile.php?id=100075547720779&mibextid=ZbWKwL"
      >
        <img src={facebook} className="social-icon" />
      </a>
      <a target="_blank" href="https://www.instagram.com/doorpal.app/">
        <img src={instagram} className="insta-icon" />
      </a>
      <a
        target="_blank"
        href="https://twitter.com/doorbellboost?t=1eiSb5Zi7QsB5Y1A2icGFg&s=09"
      >
        <img src={twitter} className="social-icon" />
      </a>
      <a target="_blank" href="https://www.youtube.com/@DoorPalApp">
        <img src={youtube} className="social-icon" />
      </a>
    
    </div>
  );
};

export default StickySocialIcons;
