import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "./sliderstyle.css";

function Slider({ slides, isMobile, PlayStore, AppStore }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [fadeOut, setFadeOut] = useState(false); // For fade-out animation

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeOut(true); // Trigger fade-out animation

      setTimeout(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length); // Change slide
        setFadeOut(false); // Reset fade-out
      }, 1000); // Wait 3 seconds before changing slide
    }, 8000); // Total interval (5s display + 3s transition)

    return () => clearInterval(interval); // Cleanup interval
  }, [slides.length]);

  return (
    <div className={`slider-section ${fadeOut ? "fade-out" : "fade-in"}`}>
      <Row>
        {/* Text Section */}
        <Col md={6} className="slider-text" style={{ marginTop: isMobile ? "" : "14%" }}>
          {/* Title */}
          <h1 className="slider-title">{slides[currentSlide].title}</h1>

          {/* Description */}
          <div className="slider-description">
            {slides[currentSlide].text.map((line, index) => (
              <span key={index} className="slider-line">
                <strong>{line}</strong>
              </span>
            ))}
          </div>

          {/* Buttons */}
          <div className="buttons-container">
            <img
              src={PlayStore}
              alt="Android App"
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.doorpalandroid",
                  "_blank"
                )
              }
              className="store-button"
            />
            <img
              src={AppStore}
              alt="Apple App"
              onClick={() =>
                window.open(
                  "https://apps.apple.com/pk/app/door-pal/id6479244948",
                  "_blank"
                )
              }
              className="store-button"
            />
          </div>
        </Col>

        {/* Image Section */}
        <Col md={6} className="slider-image">
          <img
            src={slides[currentSlide].image}
            alt="slider"
            className="img-fluid"
            style={{
              objectFit: "contain",
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Slider;
